let $loader = null;

$(function () {
    $loader = $('#paranja');
    if ($loader.length) return;

    $loader = $(`<div id="paranja" hidden class="paranja loading">`);
    $('body').append($loader);
});

const loader = value => $loader.attr('hidden', !value);
window.loader = window.loader ?? loader;

export default loader;
