export function notification(obj){
    let status = '';
    let text = $('<div class="k-notif__body">');
    
    if(obj.status){
        status = obj.status ;
    }

    if(obj.text){
        if(typeof obj.text === 'object'){
            for (let key in obj.text) {
                if(key === 'message'){
                    const p = $('<p>').text(obj.text[key]);
                    text.append(p);
                }

                if(key === 'errors'){
                    $.each(obj.text[key], function (index, value) {
                        const p = $('<p>').text(value);
                        text.append(p);
                    })
                }
            }
        }else{
            const p = $('<p>').append(obj.text);   // было text добавил append теперь тэги норм
            text.append(p);
        }
    }

    if(obj.class){
        $(`.${obj.class}`).attr('disabled', true).addClass('disabled');
    }

    let body = $(`
        <div class="notif-plugin ${status}">
            <div class="k-notif__img"></div>
            <div class="k-notif__close"></div>
        </div>
    `);

    body.append(text);

    $('body').append(body);

    $('.notif-plugin').click(function(e){
        e.preventDefault();
        $(this).fadeOut("slow", function() { $(this).remove(); } );

        if(obj.class){
            $('.'+obj.class+'').attr('disabled', false).removeClass('disabled');
        }
        clearTimeout(timeout);
    });

    var timeout = setTimeout(function () {
        $('.notif-plugin').fadeOut("slow", function() {
            $(this).remove();
        } );
        if(obj.class){
            $(`.${obj.class}`).attr('disabled', false).removeClass('disabled');
        }
    }, 60000);

}
