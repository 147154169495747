import {notification} from "./vendors/notification";
import loader from "./vendors/loader";
import {isNumeric} from "./vendors/regex";
import {debounce} from "./vendors/debounce";
import Chart from 'chart.js'

$(document).ajaxError(function (handleObj, status) {
    // console.log('ajax errors',handleObj,)
    if (status.status === 401) {
        if (!$('.notice-backdrop').length) {
            $('body').append('<div class="notice-backdrop fade show"></div>' +
                '<div class="notice-box">' +
                '   <div class="notice-dialog">' +
                '       <div class="notice-body"><h3 class="mb-3">Время сессии истекло, чтобы продолжить работу обновите страницу.</h3>' +
                '           <button type="button" class="notice-btn k-btn-primary sm-btn">Обновить</button>' +
                '       </div>' +
                '   </div>' +
                '</div>');
        }
    }
});
$(document).ready(function () {
    $(".phone-mask").inputmask("+7(999) 999-9999");
    $(".phone-mask-cashiers").inputmask("+7(999) 999-9999");
    $(".land-phone").inputmask("+7(999) 999-9999");

    $('.js-modal-instructions').modalVideo();

    $('#closePosShift').click(function () {
        $('#formClosePosShift').submit();
    });

    $('#lock').click(function () {
        $('#lockPos').submit();
    });

    $('#logout').click(function () {
        $('#logoutForm').submit();
    });
    $('.input-group input, select').focusin(function () {
        $(this).parents('.input-group').addClass('focus-in')
    });
    $('.input-group input, select').focusout(() => {
        $('.input-group').removeClass('focus-in')
    });
    if (window.matchMedia("(min-width: 993px)").matches) {
        $('.k-collapse-mobile').remove();
    } else {
        $('.k-tab-desktop').remove();
    }
    if (!window.matchMedia("(min-width: 1200px)").matches) {
        $('.js-refresh-report').click(function () {
            $('body').removeClass('g-hidden');
            $('#toggle-menu').removeClass('toggled');
            $('#backdrop').css('display', 'none');
            hamburger_cross();
        });
    }
    $('body').on('click', '.notice-btn', function () {
        location.reload();
    });
    $.ajaxSetup({

        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Access-Mode': $('meta[name="Access-Mode"]').attr('content'),
            'Access-Token': $('meta[name="Access-Token"]').attr('content'),
            'Pos-Id': $('meta[name="pos-id"]').attr('content'),
            'Pos-Shift-Id': $('meta[name="pos-shift-id"]').attr('content'),
        },
        cache: true
    });


    if ($('.js-checkbox-status').is(':checked')) {
        $('.js-checkbox-status_label').show();
    } else {
        $('.js-checkbox-status_label').hide();
    }

    $('.js-checkbox-status').change(function (e) {
        if ($(this).is(':checked')) {
            $('.js-checkbox-status_label').show();
        } else {
            $('.js-checkbox-status_label').hide();
        }
    });

    var trigger = $('.hamburger'),
        isClosed = false;

    trigger.click(function () {
        hamburger_cross();
        window.scrollTo(0, 0);
        $('body').toggleClass('g-hidden');
        $('#backdrop').css('display', 'block')
    });

    $('#backdrop').click(function () {
        $('body').removeClass('g-hidden');
        $('#toggle-menu').removeClass('toggled');
        $('#backdrop').css('display', 'none');
        hamburger_cross();
    });


    function showGiftModal() {
        $('#gift').show();
        $('.nk-modal__backdrop').show();
    }

    $('#js-gift-closed').click(() => {
        $('#gift').hide();
        $('.nk-modal__backdrop').hide();
    });

    $('.btn-gift').click(showGiftModal)

    function hamburger_cross() {
        if (isClosed) {
            trigger.removeClass('is-open');
            trigger.addClass('is-closed');
            isClosed = false;
        } else {
            trigger.removeClass('is-closed');
            trigger.addClass('is-open');
            isClosed = true;
        }
    }

    $('[data-toggle="offcanvas"]').click(function () {
        $('#toggle-menu').toggleClass('toggled');
    });

    function scrollAnchor(id, top) {
        let aTag = $('#' + id);
        $('html,body').animate({scrollTop: aTag.offset().top - top}, 'slow');
    }

    $(".js-anchor").click(function () {
        if (window.matchMedia("(min-width: 993px)").matches) {
            let anchor = $(this).data('name');
            scrollAnchor(anchor, 100);

            hamburger_cross();
        } else {
            if (!$(this).data('rates')) {
                $('.js-header-nav').removeClass('show-menu');
                $('body').removeClass('open-menu');
                $('.backdrop').remove()
                hamburger_cross();
            }
            let anchor = $(this).data('name');
            scrollAnchor(anchor, 70);
        }
    });

    // закрыть модалку по клику назад
    // if ($(window).width() < 991) {
    //     history.pushState(null, null, document.url);
    //     window.addEventListener('popstate', function () {
    //         // get modal element
    //         // check if lightbox is active
    //         if ($('.modal').is(':visible')) {
    //             history.pushState(null, null, document.url);
    //             $('.close').click();
    //
    //         } else {
    //             history.back();
    //         }
    //     });
    // }

    $('.reports-datepicker input').appendDtpicker({
        locale: "ru",
        autodateOnStart: false,
        dateFormat: "DD.MM.YYYY",
        dateOnly: true
    });
    $('.reports-dtpicker').appendDtpicker({
        locale: "ru",
        autodateOnStart: false,
        dateFormat: "YYYY-MM-DD hh:mm"
    });

    function autoClose() {
        if ($('#is_auto_close').is(':checked')) {
            $('.js-auto-close').show()
        } else {
            $('.js-auto-close').hide()
        }
    }

    function serviceMarkup() {
        if ($('#service_markup').is(':checked')) {
            $('.js-service-markup').show()
        } else {
            $('.js-service-markup').hide()
        }
    }

    $('#is_auto_close').change(() => autoClose());
    $('#service_markup').change(() => serviceMarkup());

    let ofdIdReadOnly = false;
    $('.js-edit-cashbox').click(function () {

        let url = $(this).data('url');
        let update = $(this).data('submit');

        let mainEditForm = $('#mainEditForm');
        let editName = $('#editName');
        let editKkm = $('#editKkm_id');
        let editAddress = $('#editAddress');
        let editFactory_number = $('#editFactory_number');
        let editRegistration_number = $('#editRegistration_number');
        let editCashier_pin_code = $('#editCashier_pin_code');
        let editSale_point_id = $('#editSale_point_id');
        let editTimeZone = $('#editTimeZone');
        let purchaseMode = $('#purchaseMode');
        let commentMode = $('#commentMode');
        let isBin = $('#is_bin');
        let $autoClose = $('#is_auto_close');
        let isWithdrawMoney = $('#is_withdraw_money');
        let minutes = $('#minutes');
        let hours = $('#hours');

        let $serviceMarkup = $('#service_markup');
        let serviceMarkupPercent = $('#service_markup_percent');

        let advert = $('#advert');

        let autoCloseEmail = $('#email');
        let cashierName = $('#cashier_name');


        editRegistration_number.prop('readonly', false);
        mainEditForm.attr('action', update);

        $.ajax({
            url: url,
            type: "GET",
            success: function (item) {
                let data = item.data.pos;

                editName.val(data.name);
                editKkm.val(data.kkm_id);
                editAddress.val(data.address);
                editFactory_number.val(data.factory_number);
                editRegistration_number.val(data.registration_number);
                editCashier_pin_code.val(data.cashier_pin_code);
                editTimeZone.val(data.time_zone);
                editSale_point_id.val(data.sale_point_id);
                cashierName.val(data.cashier_name);
                advert.val(data.advert);
                if (data.is_validated) {
                    editRegistration_number.prop('readonly', true);
                    ofdIdReadOnly = true
                }
                if (data.auto_close_time) {
                    let timerArr = data.auto_close_time.split(':');
                    hours.val(timerArr[0]);
                    minutes.val(timerArr[1]);
                }
                autoCloseEmail.val(data.email);
                if (data.purchase_mode) {
                    purchaseMode.prop('checked', true);
                } else {
                    purchaseMode.prop('checked', false);
                }

                if (data.comment_mode) {
                    commentMode.prop('checked', true);
                } else {
                    commentMode.prop('checked', false);
                }

                if (data.is_bin) {
                    isBin.prop('checked', true);
                } else {
                    isBin.prop('checked', false);
                }

                if (data.is_withdraw_money) {
                    isWithdrawMoney.prop('checked', true);
                } else {
                    isWithdrawMoney.prop('checked', false);
                }
                if (data.is_auto_close) {
                    $autoClose.prop('checked', true);
                } else {
                    $autoClose.prop('checked', false);
                }

                serviceMarkupPercent.val(data.service_markup_percent);
                if (data.service_markup) {
                    $serviceMarkup.prop('checked', true);
                } else {
                    $serviceMarkup.prop('checked', false);
                }

                autoClose();
                serviceMarkup();
            }
        })

    });

    let updateData = {
        name: '',
        sale_point_id: '',
        kkm_id: '',
        discount_name: '',
        discount: 0,
        markup: 0,
        time_zone: '',

    };

    $('#minutes').keypress(function () {
        let $this = $(this);
        calcLength($this)
    });
    $('#minutes').keyup(function () {
        let $this = $(this);
        calcLength($this)
    });
    $('#hours').keypress(function () {
        let $this = $(this);
        calcLength($this)
    });
    $('#hours').keyup(function () {
        let $this = $(this);
        calcLength($this)
    });

    $('.js-update-cashbox').click(function () {

        let editName = $('#editName').val();
        let editSale_point_id = $('#editSale_point_id').val();
        let editRegistration_number = $('#editRegistration_number').val();
        let editKkm_id = $('#editKkm_id').val();
        let editAddress = $('#editAddress').val();
        let editTimeZone = $('#editTimeZone').val();
        let purchaseMode = $('#purchaseMode');
        let commentMode = $('#commentMode');
        let isBin = $('#is_bin');
        let autoClose = $('#is_auto_close');
        let autoCloseEmail = $('#email');
        let cashierName = $('#cashier_name').val();
        let isWithdrawMoney = $('#is_withdraw_money');
        let advert = $('#advert');

        let minutes = $('#minutes');
        let hours = $('#hours');

        let $serviceMarkup = $('#service_markup');
        let serviceMarkupPercent = $('#service_markup_percent');

        if (purchaseMode.is(':checked')) {
            updateData.purchase_mode = 1;
        } else {
            updateData.purchase_mode = 0;
        }

        if (commentMode.is(':checked')) {
            updateData.comment_mode = 1;
        } else {
            updateData.comment_mode = 0;
        }

        if (isBin.is(':checked')) {
            updateData.is_bin = 1;
        } else {
            updateData.is_bin = 0;
        }

        if (isWithdrawMoney.is(':checked')) {
            updateData.is_withdraw_money = 1;
        } else {
            updateData.is_withdraw_money = 0;
        }
        updateData.email = autoCloseEmail.val();
        updateData.cashier_name = cashierName;

        if (autoClose.is(':checked')) {
            updateData.is_auto_close = 1;
            updateData.auto_close_time = hours.val() + ':' + minutes.val();
            if (minutes.val().length === 0 && hours.val().length === 0 || hours.val().length > 2 && minutes.val().length > 2 || +hours.val() > 23 || +minutes.val() > 59) {
                notification({
                    status: 'error',
                    text: 'Укажите  правильный формат времени'
                });
                return false
            }
        } else {
            updateData.is_auto_close = 0;
        }
        if ($serviceMarkup.is(':checked')) {
            updateData.service_markup = 1;
            updateData.service_markup_percent = serviceMarkupPercent.val();
        } else {
            updateData.service_markup = 0;
        }
        updateData.advert = advert.val();
        updateData.name = editName;
        updateData.sale_point_id = editSale_point_id;
        if (!ofdIdReadOnly) {
            updateData.registration_number = editRegistration_number;
        }
        updateData.kkm_id = editKkm_id;
        updateData.time_zone = editTimeZone;
        updateData.address = editAddress;


        let mainEditForm = $('#mainEditForm');

        let url = mainEditForm.attr('action');

        $.ajax({
            url: url,
            type: "PUT",
            data: updateData,
            success: function (data) {
                notification({
                    status: 'success',
                    text: data.message,
                });
                $('#posEditModal').modal('hide');

                location.reload()
            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })
    });

    $('.js-w__send-code').click(function () {
        let p = $('#wipon_phone');
        if (p.inputmask("unmaskedvalue").length !== 10) {
            notification({
                status: 'error',
                text: 'Номер телефона введен некорректно'
            });

            return false
        }
        $.ajax({
            type: 'POST',
            url: '/wipon/subscription/check',
            data: {phone_number: p.val()},
            success: function () {
                $('.code-header').hide();
                $('.code-body').show();
                $('.js-new-cashbox').removeClass('d-none');
                notification({
                    status: 'success',
                    text: 'В мобильное приложение "Wipon для реализаторов" отправлен код подтверждения',
                });
            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })
    });
    $('#wipon_checkbox').change(function () {
        $('.code-header').toggleClass('d-none');
        $('.js-new-cashbox').toggleClass('d-none');
    });
    $('.js-new-cashbox').click(function () {

        let editName = $('#name').val();
        let editSale_point_id = $('#sale_point_id').val();
        let editRegistration_number = $('#registration_number').val();
        let editKkm_id = $('#kkm_id').val();
        let address = $('#address').val();
        let editTimeZone = $('#TimeZone').val();
        let ofdConnection = $('#ofd_connection').val();

        updateData.name = editName;
        updateData.sale_point_id = editSale_point_id;
        updateData.registration_number = editRegistration_number;
        updateData.kkm_id = editKkm_id;
        updateData.address = address;
        updateData.time_zone = editTimeZone;
        updateData.ofd_connection = ofdConnection;

        let mainNewForm = $('#newCashbox');

        let url = mainNewForm.attr('action');

        $.ajax({
            url: url,
            type: "POST",
            data: updateData,
            success: function (data) {
                notification({
                    status: 'success',
                    text: data.message,
                });
                $('#posModal').modal('hide');

                location.reload()
            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })
    });

    $('.js-edit-token').click(function () {
        let url = $(this).data('url');
        let update = $(this).data('submit');

        let mainEditForm = $('#mainEditToken');
        let editName = $('#ofd_token_edit');

        mainEditForm.attr('action', update);

        $.ajax({
            url: url,
            type: "GET",
            success: function (data) {
                let item = data.data.pos;

                editName.val(item.ofd_token);

            }
        });
    });

    $('.js-get-qr').click(function () {
        let url = $(this).data('url');

        $.ajax({
            url: url,
            type: "GET",
            success: function (data) {
                window.open(data.link, '_blank');
            },

            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        });
    });

    $('.js-notif-page').click(function (e) {
        e.preventDefault();
        $(this).fadeOut("slow", function () {
            $(this).css('display', 'none');
        });
    });
    let wiponRoutes = {
        posId: '',
        confirmed: '',
        activate: ''
    };
    $('.js-posActivateWipon').click(function () {
        wiponRoutes.posId = $(this).attr('data-pos');
        wiponRoutes.confirmed = $(this).attr('data-confirmed');
        wiponRoutes.activate = $(this).attr('data-activate');
        $('#wipon-phone').val('');
        $('#wipon-code').val('');
        $('.js-wipon-step-1').show();
        $('.js-wipon-step-2').hide();
        $('.js-confirm-wipon').prop('disabled', false);
        $('.js-activate-wipon').prop('disabled', false);
    });

    $('.js-confirm-wipon').click(function () {
        let $this = $(this);
        $this.prop('disabled', true);
        $.ajax({
            url: wiponRoutes.confirmed,
            method: 'POST',
            data: {
                pos_id: wiponRoutes.posId,
                phone_number: $('#wipon-phone').val()
            },
            success: function (data) {
                $('.js-wipon-step-1').hide();
                $('.js-wipon-step-2').show();
            },
            error: function (data) {
                $this.prop('disabled', false);
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })

        $('#posId').val($this.attr('data-pos'))
    });
    $('.js-activate-wipon').click(function () {
        let $this = $(this);
        $this.prop('disabled', true);
        $.ajax({
            url: wiponRoutes.activate,
            method: 'POST',
            data: {
                pos_id: wiponRoutes.posId,
                phone_number: $('#wipon-phone').val(),
                code: $('#wipon-code').val()
            },
            success: function (data) {
                location.reload();
            },
            error: function (data) {
                $this.prop('disabled', false);
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })
        $('#posId').val($this.attr('data-pos'))
    });
    let goodsSale = $('#goodsSale');
    let goodsMarkup = $('#goodsMarkup');
    let jsNumeric = $('.js-numeric');

    jsNumeric.keypress(function (e) {
        isNumeric(e);
    });

    goodsSale.keyup(function () {
        let $this = $(this);
        calcLength($this)
    });

    goodsSale.keypress(function (e) {
        isNumeric(e)
    });

    goodsMarkup.keyup(function () {
        let $this = $(this);
        calcLength($this)
    });
    goodsMarkup.keypress(function (e) {
        isNumeric(e)
    });

    let valid = $('.valid-field');

    valid.keypress(function () {
        let $this = $(this);
        calcLength($this)
    });
    valid.keyup(function () {
        let $this = $(this);
        calcLength($this)
    });

    function calcLength(item) {
        let dataName = item.data('name');
        let maxLength = '';

        if (dataName === 'bin') {
            maxLength = 12;
        }

        if (dataName === 'pin') {
            maxLength = 4;
        }

        if (dataName === 'discount') {
            maxLength = 2;
        }
        if (dataName === 'ctQuantity') {
            maxLength = 1;
        }

        if (dataName === 'date') {
            maxLength = 2;
        }

        if (item.val().length > maxLength)
            item.val(item.val().slice(0, maxLength));
    }

    $('#goodsPrice').keypress(function (e) {
        isNumeric(e)
    });


    function splitNumber(el,) {
        let splitArr = el.val().split('.');
        if (splitArr[1] && splitArr[1].length > 2) {
            return splitArr[0] + '.' + splitArr[1].slice(0, 2);
        }
    }

    let $floatNumber = $('#floatNumber');

    $floatNumber.keypress(function (e) {
        isNumeric(e)
    });
    $floatNumber.on('input', function () {
        let value = splitNumber($floatNumber);
        if (value) $floatNumber.val(value)
    });

    $('#paymentContent').on('input', function () {
        let fieldPayment = $(this).find('.js-payment');
        fieldPayment.keypress(function (e) {
            isNumeric(e)
        });
    });


    //payment

    let formatter = new Intl.NumberFormat("ru");
    let checkbox = $('input[name=payment_method]');
    let content = $('.js-payment-box');
    let sendPaymentBtn = $('.js-payment-sendBtn');
    let paymentBlock = $('.js-payment-block');

    let $checkoutMinus = $('#ctMinus');
    let $checkoutPlus = $('#ctPlus');
    let $checkoutKeysQuantity = $('#ctKeysQuantity');
    let $ctPrice = $('.jsct-price').data('price');
    let $ctOldPrice = $('.jsct-old-price');
    let $ctBoxTotal = $('.jsct-box-total');
    let $ctTotal = $('.jsct-total');
    let $ctAmount = $('.jsct-amount');
    let $ctBalance = $('.jsct-balance').data('balance');
    let ctQuantity = +$checkoutKeysQuantity.val();
    let $ctLangAmount = $('.js-checkout-amount');

    $checkoutPlus.click(function () {
        ctQuantity++;
        if (ctQuantity > 99) {
            ctQuantity = 99;
        }
        $checkoutKeysQuantity.val(ctQuantity);
        ctCalculateTotal();
        quantityGoods();

    });
    $checkoutMinus.click(function () {
        ctQuantity--;
        if (ctQuantity <= 0) {
            ctQuantity = 1
        } else {
            $checkoutKeysQuantity.val(ctQuantity);
        }
        ctCalculateTotal();
        quantityGoods();
    });
    $checkoutKeysQuantity.keypress(function (e) {
        isNumeric(e);
        let $this = $(this);
        calcLength($this)
    });

    $checkoutKeysQuantity.on('input', function () {
        ctCalculateTotal();
        quantityGoods();
    });


    function ctCalculateTotal() {
        ctQuantity = +$checkoutKeysQuantity.val();
        $ctAmount.text(ctQuantity);
        $ctBoxTotal.text(formatter.format($ctPrice * ctQuantity));
        if ($ctOldPrice) {
            $ctOldPrice.text(formatter.format($ctOldPrice.data('price') * ctQuantity));
        }
        if (ctQuantity > 0) {
            let n = $ctPrice * ctQuantity - $ctBalance;
            if (n > 0) {
                $('input[name=kaspi]').val($ctPrice * ctQuantity - $ctBalance);
                $('input[name=alfa]').val($ctPrice * ctQuantity - $ctBalance);
                $ctTotal.text(formatter.format($ctPrice * ctQuantity - $ctBalance));

                $ctLangAmount.each(function () {
                    $(this).html('')
                    const b = $('<b>').text(formatter.format(+$ctPrice * ctQuantity - $ctBalance));
                    $(this).append(b);
                })
            } else {
                $('input[name=kaspi]').val(0);
                $('input[name=alfa]').val(0);
                $ctTotal.text('0');
            }
        }
    }

    checkbox.on('change', item => {
        content.each(function () {
            $(this).hide();
        });
        content.each(function () {
            if ($(this).attr('data-id') === item.currentTarget.value) {
                $(this).show()
            }
        })
    });

    sendPaymentBtn.click(function () {
        let dataName = $(this).attr('data-name');
        let dataUrl = $(this).attr('data-url');
        let formData = {
            quantity: ctQuantity
        };

        loader(true);

        let inputName = '';

        if (dataName === 'balance' || dataName === 'qiwi') {
            inputName = $('input[name=' + dataName + '-amount');
            formData.amount = inputName.val();
            formData.phone_number = $('input[name=' + dataName + '-phone').val();

        } else {
            inputName = $('input[name=' + dataName + '');
            formData.amount = inputName.val();
        }

        $.ajax({
            method: 'POST',
            url: dataUrl,
            data: formData
        }).then(
            function success(data) {

                if (dataName === 'kaspi') {
                    redirectPost('https://kaspi.kz/online', data.data)
                }
                if (dataName === 'alfa') {
                    location.href = data.data.response.formUrl
                }

                content.each(function () {
                    $(this).removeClass('d-block');
                });

                checkbox.each(function () {
                    $(this).prop('checked', false);
                });
                setTimeout(function () {
                    loader(false);
                    notification({
                        status: 'success',
                        text: 'Запрос на оплату отправлен'
                    })
                }, 1000)

            },
            function error(error) {
                setTimeout(function () {
                    loader(false);
                    notification({
                        status: 'error',
                        text: 'Сервис временно недоступен'
                    })
                }, 1000);
            }
        );
    });

    paymentBlock.click(function () {
        $(this).find('input').prop('checked', true).change()
    });
    let $promoCodeBtn = $('#promoCode');

    $promoCodeBtn.click(function () {
        let val = $('#promoCodeVal').val();
        let $this = $(this);
        $.ajax({
            url: '/promo?code=' + val,
            type: "GET",
            success(data) {
                notification({
                    status: 'success',
                    text: 'Промокод успешно активирован'
                });
                $('.js-promoCode-box').hide('slow', function () {
                    $('.js-promoCode-box').remove();
                    $('.js-goods-box').append(`
                        <div class="goods-box goods-promo">
                            <div class="goods-box__title">Активационный ключ NURKASSA
                                <span>На месяц</span>
                            </div>
                            <div class="goods-box__total">
                                <div class="goods-box__total--price ">0 ₸
                                    <span class="color-gray">х </span>1 шт.
                                </div>
                            </div>
                        </div>
                    `);
                });
            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })
    });

    let quantityGoods = debounce(function () {
        let qData = {
            quantity: ctQuantity
        };
        $.ajax({
            url: '/order',
            type: 'PUT',
            data: qData,
            success() {

            },
            error(data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        })
    }, 1000);

    function redirectPost(link, params) {
        var form = document.createElement('form');
        form.action = link;
        form.method = 'POST';
        for (var item in params.response) {
            var inp = document.createElement('input');
            inp.name = item;
            inp.type = 'hidden';
            inp.value = params.response[item];
            form.appendChild(inp);
        }
        document.body.appendChild(form);
        form.submit();
    }


    //after auth
    let banner = $('#banner');
    let requestCall = $('#RequestCall');

    banner.modal('show');

    $('.js-toggle-modal').click(function () {
        banner.removeClass('fade');
        requestCall.removeClass('fade');
        requestCall.modal('show');
        banner.modal('hide');
    });

    //feedback
    let modalData = {
        phone_number: '',
        title: 'Заказать звонок'
    };
    let utmSource = $('#utm_source');
    let utmMedium = $('#utm_medium');
    let utmCampaign = $('#utm_campaign');
    let utmContent = $('#utm_content');
    let utmTerm = $('#utm_term');

    $('.js-feedback-input').on('input', function () {
        let $this = $(this)
        if ($this.inputmask("unmaskedvalue").length === 10) {
            $(this).parents('.js-feedback-main')
                .find('.js-send-feedback')
                .attr('disabled', false)
                .addClass('active');
        } else {
            $this.parents('.js-feedback-main')
                .find('.js-send-feedback')
                .attr('disabled', true)
                .removeClass('active');
        }
    });
    if (!window.matchMedia("(min-width: 768px)").matches) {
        $('.js-feedback-input').each(function () {
            $(this).attr("placeholder", "+7 (xxx) xxx xx xx");
        })
    }

    $('.js-send-feedback').click(function () {
        let input = $(this).parents('.js-feedback-main')
            .find('.js-feedback-input')
        // modalData.name = $('#modalName').val();
        modalData.phone_number = input.val();
        if (utmSource.length && utmSource.val()) {
            modalData.utmSource = utmSource.val();
            modalData.utmMedium = utmMedium.val();
            modalData.utmCampaign = utmCampaign.val();
            modalData.utmContent = utmContent.val();
            modalData.utmTerm = utmTerm.val();
        }
        modalData.roistat_visit = document.cookie.match('roistat_visit=') ? document.cookie.match('(^|;)\\s*roistat_visit=([^;]*)')[2] : '';
        let number = input.inputmask("unmaskedvalue");
        if (number.length === 10) {
            $.ajax({
                type: 'POST',
                url: '/feedback',
                data: modalData,
                success: function (data) {
                    notification({
                        status: 'success',
                        text: 'Заказ принят. Спасибо!',
                    });
                    input.val('');
                    $('.js-send-feedback').removeClass('active');
                },
                error: function (data) {
                    notification({
                        status: 'error',
                        text: data.responseJSON.error,
                    });
                }
            })
        } else {
            notification({
                status: 'error',
                text: 'Номер введен некорректно',
            });
        }

    });

    $('.js-pos-send-feedback').click(function () {
        // modalData.name = $('#modalName').val();
        let posPhone = $('.js-pos-feedback-phone');
        modalData.phone_number = posPhone.val();
        modalData.name = $('.js-pos-feedback-name').val();
        if (utmSource.length && utmSource.val()) {
            modalData.utmSource = utmSource.val();
            modalData.utmMedium = utmMedium.val();
            modalData.utmCampaign = utmCampaign.val();
            modalData.utmContent = utmContent.val();
            modalData.utmTerm = utmTerm.val();
        }
        let number = posPhone.inputmask("unmaskedvalue");
        if (number.length === 10) {
            $.ajax({
                type: 'POST',
                url: '/feedback',
                data: modalData,
                success: function (data) {
                    notification({
                        status: 'success',
                        text: 'Заказ принят. Спасибо!',
                    });
                    posPhone.val('');
                    $('.js-pos-feedback-name').val('');

                    $('#pos-feedback').modal('hide');
                },
                error: function (data) {
                    notification({
                        status: 'error',
                        text: data.responseJSON.error,
                    });
                }
            })
        } else {
            notification({
                status: 'error',
                text: 'Номер введен некорректно',
            });
        }

    });

    $('#itemBuyPhone').on("keyup", function (event) {
        let value = $(this).inputmask("unmaskedvalue");
        if (value.length === 10) {
            $('.js-send-product').attr('disabled', false).removeClass('disabled');
        } else {
            $('.js-send-product').attr('disabled', true).addClass('disabled');
        }
    });
    //widget\
    let feedbackWidget = $('.js-widget');
    feedbackWidget.addClass('move');
    feedbackWidget.click(function () {
        $('.js-widget-content').toggle();
    })
    //reports footer icons
    let iconBtn = $('.js-icon-box');
    let iconContent = $('.k-icon__content');
    let iconBackBtn = $('.js-icon-back');
    let socialBox = $('.js-social-box');

    iconBtn.click(function () {
        let dataName = $(this).data('name');
        iconContent.fadeOut(300, function () {
            socialBox.each(function (item) {
                if (dataName === $(this).data('name')) {
                    $(this).fadeIn(300);
                    $('#' + dataName + '').focus();
                }
            })

        })
    });
    iconBackBtn.click(function () {
        socialBox.fadeOut(300, function () {
            setTimeout(() => {
                iconContent.fadeIn(300)
            }, 310);

        })
    });

    //checkout download input,btn
    let chDownload = $('#checkout_download');
    let chDownloadAddress = $('#checkout_address');
    chDownloadAddress.keyup(function () {
        if (chDownloadAddress.val().length > 7) {
            chDownload.prop('disabled', false);
            chDownload.removeClass('disabled')
        } else {
            chDownload.prop('disabled', true);
            chDownload.addClass('disabled')
        }
    })


    //catalog
    let minus = $('.minus');
    let plus = $('.plus');
    let itemQuantity = $('#itemQuantity');
    let qt = +itemQuantity.val();
    let sendProduct = $('.js-send-product');
    let productBuy = $('.js-product-buy');
    let cartItems = [];

    let renderCartBody = $('.js-render-cart');

    if (localStorage.getItem('cart_items')) {
        $('.cart-widget').addClass('move');
        cartItems = JSON.parse(localStorage.getItem('cart_items'));
        updateCartCount()
    }

    function updateCartCount() {
        let cartCount = 0;
        cartItems.forEach((value) => {
            cartCount += value.quantity
        });

        $('.js-cart-count').text(cartCount)
    }

    function totalUpdate(total, itemKey) {
        total.html(`</div>
            <div class="goods-box__total--price ">
                <b><span class="jsct-box-total">${formatter.format(cartItems[itemKey].price * cartItems[itemKey].quantity)}</span> ₸</b>
            </div>
            <div class="goods-box__total--price ">
                <span class="" data-price="${formatter.format(cartItems[itemKey].price)}">${formatter.format(cartItems[itemKey].price)}</span> ₸
                <span class="color-gray">х <span class="jsct-amount">${formatter.format(cartItems[itemKey].quantity)}</span> шт.</span>
            </div>
        `);
    }

    renderCartBody.on('click', '.plus', function () {
        let input = $(this).siblings('input');
        let qty = input.val();
        qty++;

        if (qty > 99) {
            qty = 99;
        }
        input.val(qty);

        let goodsBox = $(this).closest('.goods-box');
        let total = goodsBox.find('.goods-box__total');
        let itemKey = goodsBox.data('key');

        cartItems[itemKey].quantity = qty;
        localStorage.setItem('cart_items', JSON.stringify(cartItems));
        totalUpdate(total, itemKey);
    });

    renderCartBody.on('click', '.minus', function () {
        let input = $(this).siblings('input');
        let qty = input.val();
        qty--;

        let goodsBox = $(this).closest('.goods-box');
        let total = goodsBox.find('.goods-box__total');
        let itemKey = goodsBox.data('key');

        if (qty <= 0) {
            qty = 1;
        } else {
            input.val(qty);
        }

        cartItems[itemKey].quantity = qty;
        totalUpdate(total, itemKey);

        localStorage.setItem('cart_items', JSON.stringify(cartItems));
    });

    renderCartBody.on('keypress', '.keys-quantity', function (e) {
        isNumeric(e);
    });
    renderCartBody.on('keyup', '.keys-quantity', function () {
        let qty = $(this).val();
        let goodsBox = $(this).closest('.goods-box');
        let total = goodsBox.find('.goods-box__total');
        let itemKey = goodsBox.data('key');

        if (qty > 99) {
            qty = 99;
        }
        if (qty <= 0) {
            qty = 1;
        }
        $(this).val(qty);

        cartItems[itemKey].quantity = qty;
        totalUpdate(total, itemKey);

        localStorage.setItem('cart_items', JSON.stringify(cartItems));

    });

    renderCartBody.on('click', '.js-delete', function () {
        cartItems.splice($(this).data('deleteKey'), 1);

        if (isEmpty(cartItems)) {
            localStorage.removeItem('cart_items');
        } else {
            localStorage.setItem('cart_items', JSON.stringify(cartItems));
        }
        loader(true);
        location.reload();
    });


    plus.click(function () {
        qt++;
        if (qt > 99) {
            qt = 99;
        }
        itemQuantity.val(qt);
    });
    minus.click(function () {
        qt--;
        if (qt <= 0) {
            qt = 1
        } else {
            itemQuantity.val(qt);
        }
    });
    itemQuantity.keypress(function (e) {
        isNumeric(e);
    });

    productBuy.click(function () {
        let item_id = $(this).data('id');

        for (let i in cartItems) {
            if (cartItems[i].id === item_id) {
                if (itemQuantity.length) {
                    cartItems[i].quantity += +itemQuantity.val();
                } else {
                    cartItems[i].quantity++;
                }
                notification({
                    status: 'success',
                    text: 'Количество товара увеличено'
                });
                localStorage.setItem('cart_items', JSON.stringify(cartItems));
                updateCartCount();
                return;
            }
        }

        cartItems.push({
            id: item_id,
            quantity: 1
        });
        notification({
            status: 'success',
            text: 'Товар добавлен'
        });

        $('.cart-widget').addClass('move');
        localStorage.setItem('cart_items', JSON.stringify(cartItems));
        updateCartCount()
    });

    let cartCheckout = $('.js-cart-checkout');
    let customerData = $('.customer-data');
    let checkoutBtn = $('.js-checkout-btn');

    if (renderCartBody.length) {
        $.ajax({
            type: 'GET',
            url: '/cart/items',
            data: {
                cartItems
            },
            success: function (data) {
                cartItems = data;
                renderCart();
            },
            error: function (data) {
                cartItems = {};
                renderCart();
            }
        });
    }

    function isEmpty(obj) {
        for (let key in obj) {
            return false;
        }
        return true;
    }

    let isEntity = customerData.data('defaultEntity');
    $('a.js-tab').click(function () {
        let entity = $(this).data('isEntity');
        if (isEntity != entity) {
            customerData.find('input').val('');
            isEntity = entity;
        }
    });

    cartCheckout.click(function () {
        loader(true);

        let data = {};

        data.cartItems = cartItems;
        if (isEntity) {
            if (customerData.find('#entity .phone_number').inputmask("unmaskedvalue").length !== 10) {
                loader(false);
                notification({
                    status: 'error',
                    text: 'Номер введен некорректно',
                });

                return false
            }
            data.name = customerData.find('#entity .customer_name').val();
            data.phone_number = customerData.find('#entity .phone_number').val();
            data.delivery_address = customerData.find('#entity .delivery_address').val();
            data.legal_address = customerData.find('#entity .legal_address').val();
            data.bin = customerData.find('#entity .bin').val();
        } else {
            if (customerData.find('#individual .phone_number').inputmask("unmaskedvalue").length !== 10) {
                loader(false);
                notification({
                    status: 'error',
                    text: 'Номер введен некорректно',
                });

                return false
            }
            data.name = customerData.find('#individual .customer_name').val();
            data.phone_number = customerData.find('#individual .phone_number').val();
            data.delivery_address = customerData.find('#individual .delivery_address').val();
        }

        data.is_entity = isEntity;

        $.ajax({
            type: 'POST',
            url: $(this).data('url'),
            data: data,
            success: function (data) {
                localStorage.removeItem('cart_items');
                document.location.href = data.redirect_url;
            },
            error: function (data) {
                loader(false);
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        });
    });

    function renderCart() {
        if (!isEmpty(cartItems)) {
            for (let i in cartItems) {
                renderCartBody.append('' +
                    '<div class="goods-box" data-key="' + i + '">\n' +
                    '   <div class="goods-box__image">' +
                    '       <img src="/storage/' + cartItems[i].thumbnail + '" alt="">            ' +
                    '   </div>' +
                    '   <div class="goods-box__right">' +
                    '       <div>' +
                    '           <div class="goods-box__title">' + cartItems[i].title + '</div>\n' +
                    '           <div class="goods-box__delete js-delete" data-delete-key="' + i + '">Удалить из корзины</div>' +
                    '       </div>' +
                    '       <div class="goods-box__quantity">\n' +
                    '            <div class="product-details__action">\n' +
                    '            <button\n' +
                    '                    type="button"\n' +
                    '                    id="minus"\n' +
                    '                    class="btn minus">\n' +
                    '            </button>\n' +
                    '                            <input type="number"\n' +
                    '                                   name="quantity"\n' +
                    '                                   title="Количество"\n' +
                    '                                   class="keys-quantity"\n' +
                    '                                   value="' + cartItems[i].quantity + '">\n' +
                    '                            <button\n' +
                    '                                    type="button"\n' +
                    '                                    id="plus"\n' +
                    '                                    class="btn plus"\n' +
                    '                            ></button>\n' +
                    '                        </div>\n' +
                    '                    </div>' +
                    '   <div class="goods-box__total">\n' +
                    '                        <div class="goods-box__total--price">\n' +
                    '                            <b><span class="jsct-box-total">' + formatter.format(cartItems[i].price * cartItems[i].quantity) + '</span>₸</b>\n' +
                    '                        </div>\n' +
                    '                        <div class="goods-box__total--count ">\n' +
                    '                            <span class="" data-price="' + formatter.format(cartItems[i].price) + '">' + formatter.format(cartItems[i].price) + ' ₸ х</span>\n' +
                    '                            <span class="jsct-amount">' + formatter.format(cartItems[i].quantity) + ' шт.</span>\n' +
                    '                        </div>\n' +
                    '                    </div>' +
                    '</div>');

                customerData.show();
                checkoutBtn.show();
            }
        } else {
            renderCartBody.append('' +
                '    <div class="goods-box">\n' +
                '        <p>Корзина пуста</p>\n' +
                '    </div>')
        }
    }

    // agent company create
    const companyRegisterForm = $('#company-register-form');
    const companyConfirmForm = $('#company-confirm-form');
    let agentData = {};

    companyRegisterForm.find('button').click(function () {
        let url = companyRegisterForm.attr('action');

        agentData.bin = $('#bin').val();
        agentData.name = $('#name').val();
        agentData.phone_number = $('#phone_number').val();
        agentData.rate_id = $('#rate_id').val();
        agentData.quantity = $('#quantity').val();

        $.ajax({
            url: url,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            type: "POST",
            data: agentData,
            success: function (data) {
                notification({
                    status: 'success',
                    text: data.message,
                });

                companyRegisterForm.css('display', 'none');
                companyConfirmForm.css('display', 'block');
            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        });
    });

    companyConfirmForm.find('button').click(function () {
        let url = companyConfirmForm.attr('action');
        agentData.auth_code = $('#auth_code').val();

        $.ajax({
            url: url,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            type: "POST",
            data: agentData,
            success: function (data) {
                document.location.href = data.redirect_url;
            },
            error: function (data) {
                $('#auth_code').val('');
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        });
    });

    let switchBtn = $('.js-switch-theme');
    switchBtn.click(function () {
        let themeId = switchBtn.data('theme');
        let changeId;
        if (+themeId === 1) {
            changeId = 0;
        }
        if (+themeId === 0) {
            changeId = 1;
        }
        $.ajax({
            url: '/change/theme',
            type: "POST",
            data: {theme: changeId},
            success: function (data) {
                switchBtn.data('theme', changeId);
                if (+changeId) {
                    $('html').attr('dark', true);
                    $('.js-switch-icons').addClass('active')
                } else {
                    $('html').attr('dark', false);
                    $('.js-switch-icons').removeClass('active')
                }
            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        });
    });
    $('.js-export').click(() => $('.js-export-body').toggleClass('d-none'))

    let i_check_report = 1;
    function checkReport() {
        if(i_check_report > 10 )
            return;
        i_check_report = i_check_report + 1;

        $.ajax({
            url: '/check-link-report',
            type: "GET",
            success: function (data) {
                //console.log('set interval = ' + data.status_report);

                if(data.status_report == 2){
                    $('.js-report-process').hide();
                    $('.js-report-finish').removeClass('d-none');
                }

            },
            error: function (data) {
                notification({
                    status: 'error',
                    text: data.responseJSON.error,
                });
            }
        });

    }

    // promo
    $('.js-copy-link').click(function () {
        let url = document.getElementById('js-copy-link-value');
        url.focus();
        url.select();
        url.setSelectionRange(0, 99999);
        document.execCommand("copy");

        notification({
            status: 'success',
            text: 'Ссылка скопирована'
        })
    })
});
