export function toFixed2( num, precision, asNum = false ) {
    num = `${num}`.split('e')[0];
    num = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    return asNum ? +num : num;
}

export function isNumeric (evt) {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    let regex = /[0-9]|\./;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault)
            theEvent.preventDefault();
    }
}
